/* eslint-disable */
import Header from '../Header.vue'
import Footer from '../Footer.vue'
import CheckInService from '../../api/CheckInService.js'

export default{
	name:'EntryIndex',
	data(){
		return{
			searchType:'',
			bannerList:[],//banner
			KnowlegeArtList:[],//艺术鉴赏
			KnowlegeRelicList:[],//考古文物
			KnowlegeLearnList:[],//学术探讨
			classList:[],//分类列表
			ClassResList:[],//分类资源列表
			curClassIndex:0,
			keyWord:'',
			SearchWordsList:[],
			KnowlegeList:[
				{value:this.$KnowlegeArtId,label:'艺术鉴赏'},
				{value:this.$KnowlegeRelicId,label:'考古文物'},
				{value:this.$KnowlegeLearnId,label:'学术探讨'},
				// {value:this.$KnowlegeFigureId,label:'人物传记'},
				],
			defaultImg: 'this.src="' + require('../../images/索引目录数据库banner.jpg') + '"', //默认banner
		}
	},
	methods:{
		GetBanner(){ //获取banner
			var that = this
			CheckInService.GetBanner(2).then(res=>{
				if(res.Tag == 1){
					that.bannerList = res.Result
				}
			})
		},
		GetKnowlegeArtList(){//获取艺术鉴赏
			var that = this
			CheckInService.GetIndexClassResList(that.$KnowlegeArtId,12,1).then(res=>{
				if(res.Tag == 1){
					that.KnowlegeArtList = res.Result
				}
			})
		},
		GetKnowlegeRelicList(){//获取考古文物
			var that = this
			CheckInService.GetIndexClassResList(that.$KnowlegeRelicId,13,1).then(res=>{
				if(res.Tag == 1){
					console.log("KnowlegeRelicList:",res);
					that.KnowlegeRelicList = res.Result
				}
			})
		},
		GetKnowlegeLearnList(){//获取学术探讨
			var that = this
			CheckInService.GetIndexClassResList(that.$KnowlegeLearnId,3,1).then(res=>{
				if(res.Tag == 1){
					console.log("KnowlegeLearnList:",res);
					that.KnowlegeLearnList = res.Result
				}
			})
		},
		GetClassTree(){ //获取分类
			var that = this
			CheckInService.GetClassTree().then(res=>{
				if(res.Tag == 1){
					res.Result.forEach(item=>{
						if(item.id == that.$ArticelClassId){
							item.children.forEach(value=>{
								if(value.id == that.$KnowlegeArtId){
									return
								}else if(value.id == that.$KnowlegeRelicId){
									return
								}else if(value.id == that.$KnowlegeLearnId){
									return
								}else{
									that.classList.push(value)
								}
							})
							if(that.classList.length > 0){
								this.GetClassResList(that.classList[0].id,0)
							}
						}
					})
				}
			})
		},
		GetClassResList(classId,index){ //获取条目
			console.log("classId:"+classId+",index:"+index);
			var that = this
			that.curClassIndex = index
			CheckInService.GetIndexClassResList(classId,9,1).then(res=>{
				if(res.Tag == 1){
					console.log("ClassResList", res);
					that.ClassResList = res.Result
				}
			})
		},
		GetSearchWords(){ //获取搜索词
			var that = this
			CheckInService.GetSearchWords(5).then(res=>{
				if(res.Tag == 1){
					that.SearchWordsList = res.Result
				}
			})
		},
		toMoreList(typeName,classId,type){ //更多
			window.open(this.$WebUrl+'EntryList?classId='+classId + '&type='+ type + '&typeName='+ typeName)
		},
		toEntryDetail(id){ //条目详情
			window.open(this.$WebUrl+'EntryDetail?id='+id)
		},
		toList(){ //搜索
			var typeName=''
			this.KnowlegeList.find(item =>{
				if(item.value == this.searchType){
					typeName = item.label
				}
			})
			window.open(this.$WebUrl+'EntryList?classId='+this.searchType + '&type='+ 1 + '&typeName='+ typeName + '&keyWord='+this.keyWord)
		},
		chooseSearch(word){
			this.keyWord = word
			this.toList()
		},
		toSeniorSearch(){ //高级检索
			this.$router.push({
				path:'/SeniorSearch'
			})
		},
		rightClick() {
			let length = 570; // 每次移动的距离：li的宽度(114) x 5
			let ul = $('#entry_slide_ul');
			let ulwidth = ul.width();
			let ulleft = ul.position().left;

			if( -ulleft <= ulwidth - 2 * length - 30 && !ul.is(":animated")) {
				ul.animate({
					left: '-='+length
				},600)
			}
		},
		leftClick() {
			let length = 570;
			let ul = $('#entry_slide_ul');
			let ulleft = ul.position().left;

			if( ulleft < 30 && !ul.is(":animated")) {
				ul.animate({
					left: '+='+length
				},600)
			}
		}
	},
	created(){
		this.GetClassTree()
		this.GetBanner()
		this.GetKnowlegeArtList()
		this.GetKnowlegeRelicList()
		this.GetKnowlegeLearnList()
		this.GetSearchWords()
		this.searchType = this.KnowlegeList[0].value
	},
	components:{
		'Header': Header,
		'Footer':Footer
	}
}